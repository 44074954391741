@font-face {
  font-family: "Ezra";
  src: url("/public/font/Ezra\ Regular.otf");
}
body {
  margin: 0;
}

/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
 */
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

a:link {
  text-decoration: none;
}

a:visited {
  color: lightgrey;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

::placeholder { 
  color: #9f9f9f;
  opacity: 1; 
}
