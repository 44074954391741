.document-logMessage {
  height: 24px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 133.02%;

  /* identical to box height, or 24px */
  text-align: center;
  animation-name: fade-in;
  animation-duration: 3s; /* 
  color: #14ff00; */
  color:#4673fa
}

@keyframes fade-in {
  0% {
    height: 0px;
    opacity: 0;
  }
  30% {
    height: 24px;
    opacity: 1;
  }
  70% {
    height: 24px;
    opacity: 1;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}
